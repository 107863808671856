import {useMemo} from "react";
import Chip from "@mui/material/Chip";
import useSWR from "swr";
import {getSupportedChains} from "../../api/hasura";
import {SupportedChain} from "./WalletsOverview";

type ChainBadgeProps = {
    chain?: string
    chainId?: string;
}
export const ChainBadge = ({chain, chainId}: ChainBadgeProps) => {

    const {
        data: supportedChains
    } = useSWR(chainId ? "supported-chains" : null, getSupportedChains)


    const chainFromId = supportedChains?.find((c: SupportedChain) => c.chain_id === chainId)?.name;


    const RenderChainBadge = useMemo(() => {
        return (chain: string) => {
            let color = "info"


            switch (chain.toUpperCase()) {
                case "ETHEREUM":
                    color = "success"
                    break;
                case "SEPOLIA":
                    color = "warning"
                    break;
                case "OPTIMISM":
                    color = "error"
                    break;
                case "ARBITRUM":
                    color = "info"
                    break;
                default:
                    color = "primary"
                    break;
            }

            return <Chip size={"small"} color={color as any} label={chain}/>


        }
    }, [chain]);


    return RenderChainBadge(chain ?? chainFromId ?? "-");
}
