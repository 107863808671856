import {PageContentContainer} from "../styled/styled";
import {
    Alert,
    Badge,
    Box,
    Button,
    Paper,
    StepLabel,
    TableContainer,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {ArrowBackIosNew, ContentCopy, OpenInBrowser} from "@mui/icons-material";
import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CenteredColumnFlexBox, CenteredRowFlexBox, FlexBox, PanelBox} from "../simulation/create-simulation/styled";
import {mockWalletsData} from "./WalletsOverview";
import Divider from "@mui/material/Divider";
import {formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {ChainBadge} from "./ChainBadge";
import {formatPubKey} from "../../utils/commonUtils";
import {DataGrid} from "@mui/x-data-grid";
import useSWR from "swr";
import {getSafeWalletHoldings, getSmartWalletById} from "../../api/hasura";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import {useEffect, useMemo} from "react";
import Safe from "@safe-global/protocol-kit";
import {useAppState} from "../../state/AppStateProvider";
import {ROLES_MOD_ADDRESS} from "./WalletCreationFlow";
import {ethers} from "ethers";
import {setUpRolesMod, applyMembers} from "zodiac-roles-sdk";
import {TransactionResponse} from "@ethersproject/providers";
import {useWallets} from "@privy-io/react-auth";
import IconButton from "@mui/material/IconButton";
import WalletStatus from "./WalletStatus";
import {allow, apply} from "defi-kit/eth";
import {encodeBytes32String} from "defi-kit";
import {fetchRole} from "zodiac-roles-deployments";
import {LabelValueChangeCard} from "../simulation/LabelValueChangeCard";
import {TitleValueColumn} from "../../modals/AddWalletModal";


const transactions = [
    {type: "Deposit", amount: "1.5 ETH", date: "2024-06-01", status: "Completed"},
    {type: "Withdraw", amount: "2,400.00 USDC", date: "2024-05-27", status: "Completed"},
    {type: "Deposit", amount: "3,400.00 USDC", date: "2021-10-03", status: "Completed"},
];


const columns = [
    {field: 'asset', headerName: 'Asset', flex: 1},
    {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        renderCell: (item: any) => <span>{item.row.amount}</span>
    },
    {
        field: 'usdValue',
        headerName: 'USD Value',
        flex: 1,
        renderCell: (item: any) => <span>${formatMonetaryValue(item.row.usdValue)}</span>
    },
];

export const walletHoldingsMock = [
    {id: 1, asset: "ETH", amount: 10, usdValue: 35000.11},
    {id: 2, asset: "BTC", amount: 0.5, usdValue: 35000.12},
    {id: 3, asset: "USDC", amount: 10000, usdValue: 10001.47},
    {id: 4, asset: "LINK", amount: 1000, usdValue: 13000.24},
    {id: 5, asset: "AAVE", amount: 5, usdValue: 530.4},
];


export const WalletDetails = () => {

    const {wallets} = useWallets();


    const {id} = useParams();

    const {provider, setSnackBar} = useAppState();


    const [isRolesModEnabled, setIsRolesModEnabled] = React.useState(false);
    const [isRoleSetup, setIsRoleSetup] = React.useState(false);


    const [selectedTab, setSelectedTab] = React.useState("Holdings");


    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails,
    } = useSWR(id ? `wallets/${id}` : null, () => getSmartWalletById(id), {fallbackData: {}});


    const {
        data: walletHoldings,
        isLoading: isHoldingsLoading,
        isValidating: isHoldingsValidating,
        mutate: mutateWalletHoldings
    } = useSWR(walletDetails?.address ? `wallets/${walletDetails?.address}/holdings` : null, () => getSafeWalletHoldings(walletDetails?.address));


    const walletHoldingsRows = walletHoldings?.map((holding: any, index: number) => ({
        id: index + 1,
        asset: holding.token[0].symbol.toUpperCase(),
        amount: holding.token[0].total_token_amount,
        usdValue: holding.token[0].price_usd
    }));


    const totalBalance = walletHoldings?.reduce((acc: number, holding: any) => {
        return acc + holding.token[0].price_usd
    }, 0);

    console.log("walletHoldings", walletHoldings);
    console.log("walletDetails", walletDetails);
    const navigate = useNavigate()


    const onHoldingSelect = (holding: any) => {
        console.log("Holding selected", holding);
    }


    const isRolesSetup = true;
    const isWalletSetupComplete = walletDetails?.address && isRolesModEnabled && isRolesSetup;

    // const protocolKit = useMemo(async () => {
    //
    //     if (!provider) {
    //         return;
    //     }
    //
    //
    //     const safe = await Safe.init({provider: provider, safeAddress: walletDetails.address});
    //     console.log("Safe", safe);
    //
    //
    //     const modules = await safe.getModules();
    //
    //     console.log("Modules", modules);
    //     console.log("modules?.length > 0", modules?.length > 0);
    //     //TODO: is there a way to check if this module is actually a roles module?
    //     const isRolesModEnabled = modules?.length > 0;
    //     // await safe.isModuleEnabled(ROLES_MOD_ADDRESS);
    //
    //     console.log("isRolesModuleEnabled", isRolesModEnabled);
    //
    //     setIsRolesModEnabled(isRolesModEnabled);
    //
    //     return safe;
    //
    // }, [walletDetails, provider])


    useEffect(() => {

        const fetchRolesModStatus = async () => {
            console.log("fetching roles mod status");
            if (!provider) {
                console.log("NO PROVIDER");
                return;
            }

            const safe = await Safe.init({provider: provider, safeAddress: walletDetails.address});
            console.log("Safe", safe);

            //this is not consistent, it does not always return true even though module is enabled.
            let isRolesModEnabled = await safe.isModuleEnabled(ROLES_MOD_ADDRESS);
            const modules = await safe.getModules();
            console.log("modules", modules);

            const roleName = "AlmanakAgentRole";
            const roleKey = encodeBytes32String(roleName);
            const role = await fetchRole({
                address: modules[0] as `0x${string}`,
                roleKey,
                chainId: walletDetails.chain_id
            });

            console.log("role", role);

            const isRoleAdded = role !== null && role?.members[0] === walletDetails.external_own_accounts[0].address;
            console.log("isRoleAdded", isRoleAdded);

            console.log("modules", modules);
            isRolesModEnabled = modules?.length > 0;


            console.log("isRolesModuleEnabled", isRolesModEnabled);

            setIsRolesModEnabled(isRolesModEnabled);

            setIsRoleSetup(isRoleAdded);

        }

        fetchRolesModStatus();

    }, [provider, walletDetails]);

    const getDeployRolesModuleTrx = async () => {


        const saltBytes = ethers.utils.randomBytes(32);
        const saltHex = ethers.utils.hexlify(saltBytes);


        const saltNonce = saltHex as `0x${string}`;


        const rolesModTrx = setUpRolesMod({
            avatar: walletDetails?.address,
            saltNonce: saltNonce
        });

        return rolesModTrx

    };


    const getApplyRoleTRX = async (zodiacModuleAddress: string, roleName: string) => {

        const EURE = '0xcB444e90D8198415266c6a2724b7900fb12FC56E'
        const USDC = '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83'

        const permissions = [
            allow.cowswap.swap({buy: [EURE, USDC], sell: [EURE, USDC]})
        ]

        const roleKey = encodeBytes32String(roleName)
        const calls = await apply(roleKey, permissions, {
            address: zodiacModuleAddress as `0x${string}`,
            mode: 'replace', // replace the current permissions with the new ones
            log: console.debug
        })
        return calls;
    }

    const getDelegateRoleTRX = async (zodiacModuleAddress: string, chainId: any, roleName: string, members: `0x${string}`[]) => {

        const roleKey = encodeBytes32String(roleName)

        const calls = await applyMembers(
            roleKey,
            members,
            {
                chainId: chainId,
                address: zodiacModuleAddress as `0x${string}`,
                mode: "replace",
            }
        );
        const transactions = calls.map(data => ({to: zodiacModuleAddress, value: '0', data}));

        return transactions;

        // const safeTransaction = await protocolKit.createTransaction({ transactions })
        // const txResponse = await protocolKit.executeTransaction(safeTransaction)
        // if (txResponse.transactionResponse) {
        //     const transactionResponse = txResponse.transactionResponse as TransactionResponse;
        //     await transactionResponse.wait(); // Call wait() on transactionResponse
        //     console.log(`Role "${roleName}" now has members ${members}`);
        // } else {
        //     console.error('transactionResponse is undefined.');
        // }

    }


    const delegateRole = async () => {
        if (!provider) {
            console.error('provider not initialized!');
            return

        }

        const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});

        const roleName = "AlmanakAgentRole";

        const almanakEOAAddress = walletDetails?.external_own_accounts[0].address;

        const modules = await protocolKit.getModules();

        const delegateRolTrx = await getDelegateRoleTRX(modules[0], walletDetails.chain_id, roleName, [almanakEOAAddress]);

        console.log("delegateRolTrx", delegateRolTrx);

        const safeTransaction = await protocolKit.createTransaction({transactions: delegateRolTrx})
        const txResponse = await protocolKit.executeTransaction(safeTransaction)


    }
    const enableAccessForAlmanakAgent = async () => {

        if (!provider) {
            console.error('provider not initialized!');
            return

        }

        const roleName = "AlmanakAgentRole";

        const roleKey = encodeBytes32String(roleName);


        const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});

        const rolesModTrx = await getDeployRolesModuleTrx();
        const expectedZodiacModuleAddress = rolesModTrx[1].to;

        const applyRoleTrx = await getApplyRoleTRX(expectedZodiacModuleAddress, roleName)

        const transactions = [...rolesModTrx, ...applyRoleTrx]


        // return;

        const safeTransaction = await protocolKit.createTransaction({transactions: transactions})
        const txResponse = await protocolKit.executeTransaction(safeTransaction)

        if (txResponse.transactionResponse) {
            const transactionResponse = txResponse.transactionResponse as TransactionResponse;
            await transactionResponse.wait(); // Call wait() on transactionResponse
            console.log('Zodiac Roles modifier module is deployed and enabled');
            console.log('Contract address: ', expectedZodiacModuleAddress);
        } else {
            console.error('transactionResponse is undefined.');
        }

        // const safeTransaction = await protocolKit.crea


    };


    const walletStatus = useMemo(() => {
        if (walletDetails?.is_in_used) {
            return "In Use"
        }


        if (walletDetails?.is_active) {
            return "Active"
        }

        return "Submitted"
    }, [walletDetails]);

    const WalletSetupStepper = () => {
        const steps = [
            'Enable Roles Module',
            'Setup Permissions',
            'Allow Actions',
        ];


        return (
            <Box>
                {/*<Typography variant="h3" gutterBottom>*/}
                {/*    Setup Access for Almanak Agent*/}
                {/*</Typography>*/}

                {/*<Stepper sx={{maxWidth: "600px"}} activeStep={0} alternativeLabel>*/}
                {/*    {steps.map((label, index) => (*/}
                {/*        <Step key={index}>*/}
                {/*            <StepLabel>{label}</StepLabel>*/}
                {/*        </Step>*/}
                {/*    ))}*/}
                {/*</Stepper>*/}


                <Box sx={{my: 2, gap: 2}}>

                    {!isRolesModEnabled && <Alert severity={"info"}
                                                  action={<Button style={{whiteSpace: "nowrap"}}
                                                                  onClick={() => enableAccessForAlmanakAgent()}
                                                                  variant={"contained"}>Enable
                                                      Access</Button>}>
                        Please enable the Almanak Agent access to this wallet
                    </Alert>}

                    <Box sx={{my: 2}}>
                        {!isRoleSetup && isRolesModEnabled &&
                            <Button variant={"contained"} onClick={delegateRole}>Delegate Access</Button>}
                    </Box>


                    {isRoleSetup && isRolesModEnabled &&
                        <Chip color={"success"} label={"Almanak Agent is fully setup"}/>}
                    {!isRoleSetup && isRolesModEnabled && <Chip color={"success"} label={"Roles Module is Enabled"}/>}

                </Box>
            </Box>
        );
    };


    const onTabChange = (e: any) => {
        if (e.target.value) {
            setSelectedTab(e.target.value);
        }
    };

    const openSafeWallet = () => {
        if (!walletDetails?.address) {
            return;
        }
        window.open(`https://app.safe.global/home?safe=arb1:${walletDetails?.address}`)
    }
    const copyAddress = async () => {

        await navigator.clipboard.writeText(walletDetails?.address)
        setSnackBar({open: true, message: "Copied to clipboard"})
    }
    return (
        <PageContentContainer sx={{py: 2}}>
            <FlexBox sx={{alignItems: "center", justifyContent: "space-between"}}>
                <FlexBox sx={{alignItems: "center", gap: "16px"}}>
                    <Button variant="contained"
                            onClick={() => navigate("/wallets")}
                            startIcon={<ArrowBackIosNew/>}>Back</Button>
                    <h2>{walletDetails?.name}</h2>
                    <WalletStatus wallet={walletDetails}></WalletStatus>
                </FlexBox>

                <FlexBox sx={{gap: 2}}>
                    <Button variant={"outlined"}>Witdraw</Button>
                    <Button variant={"contained"}>Deposit</Button>
                </FlexBox>
            </FlexBox>

            <Divider/>


            <Box>
                {WalletSetupStepper()}
            </Box>

            {/*Upper side of wallet overview*/}
            <Box>


                <PanelBox sx={{px: 4, mb: 2}}>
                    <h3> Wallet Details</h3>
                    <span style={{
                        fontSize: "42px",
                        fontWeight: "bold"
                    }}>${formatMonetaryValue(totalBalance)}</span>


                    <CenteredColumnFlexBox>

                            <CenteredRowFlexBox sx={{gap: 4}}>
                                <TitleValueColumn title={"Wallet Name"} value={walletDetails?.name}/>
                                <TitleValueColumn title={"Status"} value={<WalletStatus wallet={walletDetails}/>}/>
                            </CenteredRowFlexBox>

                            <CenteredRowFlexBox sx={{gap: 4}}>
                                <TitleValueColumn title={"Chain"}
                                                  value={<ChainBadge chainId={walletDetails?.chain_id}/>}/>

                                <TitleValueColumn title={"Address"} value={
                                    <>
                                        {formatPubKey(walletDetails?.address)}
                                        <IconButton onClick={copyAddress}><ContentCopy fontSize={"small"}/></IconButton>
                                        <IconButton onClick={openSafeWallet}><OpenInBrowser fontSize={"small"}/></IconButton>
                                    </>
                                }/>
                            </CenteredRowFlexBox>

                    </CenteredColumnFlexBox>


                </PanelBox>


                <FlexBox sx={{justifyContent: "space-between", width: "100%", gap: 4}}>

                    {/*Wallet holdings side*/}

                    <Box sx={{borderRight: "1px solid #eaeaea"}}/>


                    {/*Transactions side*/}
                    {/*<CenteredColumnFlexBox sx={{alignItems: "center", width: "100%"}}>*/}
                    {/*    <h3 style={{textAlign: "center"}}>Transactions</h3>*/}


                    {/*    <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%", gap: 2}}>*/}

                    {/*        {transactions.map((transaction, index) => (*/}
                    {/*            <FlexBox key={index} sx={{*/}
                    {/*                gap: 2,*/}
                    {/*                width: "100%",*/}
                    {/*                justifyContent: "space-between",*/}
                    {/*                border: "1px solid #eaeaea",*/}
                    {/*                p: "8px 16px",*/}
                    {/*                borderRadius: "8px"*/}
                    {/*            }}>*/}
                    {/*                <FlexBox sx={{gap: 1}}>*/}
                    {/*                    <div>{transaction.type}</div>*/}
                    {/*                    <div>{transaction.amount}</div>*/}
                    {/*                </FlexBox>*/}
                    {/*                <CenteredColumnFlexBox sx={{gap: 1}}>*/}
                    {/*                    <div>{transaction.date}</div>*/}
                    {/*                </CenteredColumnFlexBox>*/}
                    {/*            </FlexBox>*/}
                    {/*        ))}*/}
                    {/*    </CenteredColumnFlexBox>*/}
                    {/*</CenteredColumnFlexBox>*/}

                </FlexBox>


                <Divider sx={{my: 2}}/>

                <ToggleButtonGroup
                                   onChange={onTabChange}
                                      value={selectedTab}
                                   fullWidth size="small">
                    {["Holdings", "Transactions"]?.map(timeframe =>
                        <ToggleButton key={timeframe} sx={{textTransform: "none"}}
                                      value={timeframe}>{timeframe}</ToggleButton>)}
                </ToggleButtonGroup>

                <TableContainer component={Paper} sx={{my: 2}}>

                    {walletHoldingsRows && <DataGrid
                        onRowClick={(item) => onHoldingSelect(item.row)}
                        rows={walletHoldingsRows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                    />}
                </TableContainer>


            </Box>

        </PageContentContainer>
    )
}
